import React from 'react';
import axios from "axios";
import Cookies from 'js-cookie';

const UserContext = React.createContext();

let burl = process.env.APIPATH || "https://api2.mypacome-manager.fr"
//burl = "http://localhost:3010"

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

//http://192.168.1.12:3010 http://localhost:3010 https://api.mypacome-manager.fr


export class UserProvider extends React.Component {
    state = {
        user: {},
        token: null,
        data: {}
    }
    header = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    headerAuth = () => {
        return {
            headers: {
                "Content-Type": "application/json",
                "token": this.state.token
            }
        }
    }
    headerAuthFiles = () => {
        return {
            headers: {
                "Content-Type": "multipart/form-data",
                "token": this.state.token
            }
        }
    }
    setUser = async (user, token) => {
        return new Promise(((resolve, reject) => {
            this.setState({user: user, token: token}, (err) => {
                if (err) reject(err)
                else resolve()

            })
        }))
    }
    setData = (data) => {
        return new Promise(((resolve, reject) => {
            this.setState({data: data}, (err) => {
                if (err) reject(err)
                else resolve()
            })
        }))
    }
    logout = () => {
        Cookies.remove('MyPacomeClient', {path: '/'})
        this.setState({user: {}, token: ''})
    }
    isLogged = () => {
        return !!this.state.token
    }
    getBurl = () => {
        return burl
    }
    apiReducer = (action, payload, option) => {
        switch (action) {
            case 'login': {
                return new Promise((resolve, reject) => {
                    axios.post(
                        burl + '/client/login',
                        payload,
                        this.header
                    )
                        .then((response) => {
                            if (response.data.generatePassword) return resolve({generatePassword: response.data.generatePassword})
                            this.setUser(response.data.user, response.data.token)
                                .then(() => {
                                    if (option.remember) {
                                        Cookies.set('MyPacomeClient', {
                                            user: response.data.user,
                                            token: response.data.token
                                        }, {path: '/'}, {expires: 1})
                                        return resolve()
                                    } else {
                                        Cookies.set('MyPacomeClient', {
                                            user: response.data.user,
                                            token: response.data.token
                                        }, {expires: 1, path: '/'})
                                        return resolve()
                                    }
                                })
                                .catch((err) => {
                                    return reject(err)
                                })
                        })
                        .catch((err) => {
                            return reject(err)
                        })
                })
            }
            case 'reset':
                return new Promise((resolve, reject) => {
                    axios.post(
                        burl + '/client/reset/',
                        payload,
                        this.header
                    )
                        .then((result) => {
                            console.log(result)
                            resolve(result)
                        })
                        .catch(e => reject(e))
                })
            case 'setPassword':
                return new Promise(((resolve, reject) => {
                    axios.put(
                        burl + '/client/resetPassword/',
                        payload,
                        this.header
                    )
                        .then((result) => resolve(result))
                        .catch((e) => reject(e))
                }))
            case 'creation':
                return new Promise(((resolve, reject) => {
                    axios.post(
                        burl + '/client/creation/',
                        payload,
                        this.header
                    )
                        .then((result) => resolve(result))
                        .catch((e) => reject(e))
                }))
            case 'fetchData': {
                return new Promise((resolve, reject) => {
                    console.log(this.state.token)
                    axios.get(
                        burl + '/client/all/',
                        {
                            headers: {
                                token: this.state.token
                            }
                        }
                    )
                        .then((response) => {
                            this.setData({houses: response.data.properties})
                                .then(() => {
                                    return resolve()
                                })
                                .catch((err) => {
                                    console.log(err)
                                    return reject(err)
                                })
                        })
                        .catch(e => {
                            console.log(e.message)
                            return reject(e)
                        })
                })
            }
            case 'addBooking':
                return new Promise((resolve, reject) => {
                    axios.post(
                        burl + '/client/registered/properties/' + option.name,
                        payload,
                        this.headerAuth()
                    )
                        .then(() => {
                            return resolve()
                        })
                        .catch(e => {
                            return reject(e)
                        })
                })
            default: {
                throw new Error(`Unhandled action type: ${action}`)
            }
        }
    }

    componentWillMount() {
        let cookie = Cookies.get('MyPacomeClient')
        if (cookie) {
            cookie = JSON.parse(cookie)
            this.setUser(cookie.user, cookie.token)
        }
    }

    render() {
        const {children} = this.props
        const {user, token, data} = this.state
        const {logout, isLogged, getBurl, searchIntervention, apiReducer} = this

        return (
            <UserContext.Provider
                value={
                    {user, token, data, apiReducer, searchIntervention, logout, isLogged, getBurl}
                }>
                {children}
            </UserContext.Provider>
        )
    }
}

export default UserContext
