import React from 'react'
import UserContext from "../userContext";

// Images
import logo from '../images/mypacomefavicon.svg'

export default function Header(props) {
    const userContext = React.useContext(UserContext)

    return (
        <div>
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3" onClick={() => {
                    props.show.setValue(!props.show.value)
                }}>
                    <i className="fa fa-bars" />
                </button>

                <ul className="navbar-nav ml-auto">


                    <div className="topbar-divider d-none d-sm-block" />


                    <li className="nav-item dropdown no-arrow">
                        <div className="nav-link dropdown-toggle" id="userDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                                {userContext.user.lastName} {userContext.user.name}
                            </span>
                            <img className="img-profile rounded-circle"
                                src={userContext.user.photo || logo}
                                alt={"Avatre"} />
                        </div>

                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="userDropdown">
                            <div className="dropdown-divider" />
                            <button className="dropdown-item" data-toggle="modal" data-target="#logoutModal"
                                onClick={() => {
                                    userContext.logout()
                                }}>
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                                Déconnexion
                            </button>
                        </div>
                    </li>

                </ul>

            </nav>
        </div>
    )
}