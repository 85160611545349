import React from 'react';

import UserContext from "../userContext";

import LeftSide from './Leftside';
import Header from './Header'
import Footer from './Footer'
import Loading from "./Loading";

export function Layout(props) {
    const [loading, setLoading] = React.useState(true)
    const [first, setFirst] = React.useState(true)
    const [show, setShow] = React.useState(false)

    const userContext = React.useContext(UserContext)

    React.useEffect(() => {
        if (loading && first) {
            setFirst(false)
            const update = userContext.apiReducer('fetchData')
            update
                .then(() => {
                    setLoading(false)
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }, [userContext, loading, setLoading, first, setFirst])

    return (
        <div>
            <div id="wrapper">
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header show={{value: show, setValue: (value) => setShow(value)}}/>
                        {
                            loading
                                ? <Loading/>
                                : props.children
                        }
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    )
}

export default Layout