import React from 'react'
import UserContext from "../userContext";
import { useHistory } from "react-router-dom";
import Image from 'react-image-webp';

//components
import Loader from "../Layout/Components/Loader";
import '../styles/login.scss'
export default function Login() {
    const [email, setEmail] = React.useState('')
        , [password, setPassword] = React.useState('')
        , [remember, setRemember] = React.useState(false)
        , [loading, setLoading] = React.useState(false)

    const userContext = React.useContext(UserContext)
        , history = useHistory();


    return (
        <div className="parent">
            <div className={'background'} />
            <div className="container full-height align-items-center">
                <div className={'row full-height align-items-center justify-content-md-center'}>
                    <div className={'col col-lg-5 col-sm-12 align-items-center align-content-lg-center loginBox'}>
                        <div className={'content'}>
                            <Image webp={"https://i.ibb.co/3CgD7G4/new-Colors-Logo-2.webp"} src={"https://i.ibb.co/3CgD7G4/new-Colors-Logo-2.webp"} alt={"logo"} className={"col-6"} />
                            <div className={"col-12"}>
                                <h3>MYPacôme</h3>
                                <h4>Home Manager</h4>
                            </div>


                            <form className="user" onSubmit={(e) => {
                                e.preventDefault()
                                setLoading(true)
                                userContext.apiReducer('login', {
                                    email: email,
                                    password: password
                                }, { remember: remember })
                                    .then((result) => {
                                        setLoading(false)
                                        if (result) {
                                            if (result.generatePassword) {
                                                history.push('/generate')
                                            }
                                        } else {
                                            history.push('/')
                                        }
                                    })
                                    .catch((err) => {
                                        setLoading(false)
                                        alert("Email ou mot de passe incorrect")
                                        console.log(err)
                                    })
                            }}>
                                <div className="form-group">
                                    <input type="email" className="form-control form-control-user"
                                        id="exampleInputEmail" aria-describedby="emailHelp"
                                        placeholder="Adresse email" value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                        }} required />
                                </div>
                                <div className="form-group">
                                    <input type="password"
                                        className="form-control form-control-user"
                                        id="InputPasswordValidation" placeholder="Mot de passe"
                                        value={password} onChange={(e) => {
                                            setPassword(e.target.value)
                                        }}
                                        required />
                                </div>
                                <div className="form-group">
                                    <div className="custom-control custom-checkbox small">
                                        <input type="checkbox" className="custom-control-input"
                                            id="customCheck"
                                            onChange={e => setRemember(e.target.checked)}
                                            checked={remember} />
                                        <label className="custom-control-label"
                                            htmlFor="customCheck">
                                            Se souvenir de moi
                                        </label>
                                    </div>
                                </div>
                                <button className={"btn btn-primary btn-user btn-block fancy-button"}
                                    type={"submit"}>
                                    Connexion
                                </button>
                            </form>
                            <hr />
                            <div className="text-center">
                                <button className="btn btn-user btn-block fancy-button" onClick={() => {
                                    history.push('/reset')
                                }}>
                                    Mot de passe oublié ?
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Loader show={loading} message={'Envoi de la demande. Veuillez patienter.'} />
        </div>
    )
}