import React from "react";
import {Modal} from 'react-bootstrap';
import {DateRange} from 'react-date-range';
import UserContext from "../../../userContext";
import Loader from "../Loader";
import * as locales from 'react-date-range/dist/locale';

export default function Booking(props) {
    const [dates, setDates] = React.useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    })
        , [disabled, setDisabled] = React.useState([])
        , [error, setError] = React.useState(false)
        , [desc, setDesc] = React.useState('')
        , [loading, setLoading] = React.useState(false)
        , userContext = React.useContext(UserContext)

    React.useEffect(() => {
        const disabledTemp = []
        props.house.bookings.map((booking) => {
            let start = new Date(booking.dates.start)
            const end = new Date(booking.dates.end)
            if (start < end && !booking.cancelled) {
                while (start <= end) {
                    disabledTemp.push(new Date(start));
                    start = addDays(start, 1);
                }
                return null
            } else {
                return null
            }
        })
        setDisabled(disabledTemp)
    }, [setDisabled, props])

    return (
        <Modal show={props.data.addBooking} onHide={() => props.close()}>
            <Modal.Header closeButton>
                <Modal.Title>Bloquez votre maison</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row justify-content-center">
                        <form id={'bookingForm'}>
                            {error ?
                                <p className={'text-warning'}> Remplissez la description et sélectionnez une date</p>
                                :
                                null
                            }
                            <DateRange onChange={item => {
                                setDates(item.selection)
                            }}
                                       ranges={[dates]}
                                       disabledDates={disabled}
                                       locale={locales['fr']}
                                       requierd
                                       direction="vertical" scroll={{enabled: true}}/>
                            <div className="input-group mt-4">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Description</span>
                                </div>
                                <textarea className="form-control" aria-label="Description"
                                          required aria-errormessage={'Veuillez décrire votre réservation'}
                                          onChange={(e) => (
                                              setDesc(e.target.value)
                                          )}/>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={() => props.close()}>Annuler</button>
                <button type="submit" className="btn btn-success" form={"bookingForm"} onClick={(e) => {
                    e.preventDefault()
                    if ((dates.startDate === new Date() && dates.endDate === new Date()) || desc === '') {
                        setError(true)
                    } else {
                        setLoading(true)
                        const response = userContext.apiReducer('addBooking', {
                            dates: {
                                start: dates.startDate,
                                end: dates.endDate,
                            },
                            description: desc,
                            platform: "back"
                        }, {name: props.house.name})
                        response
                            .then(() => {
                                setLoading(false)
                                props.close()
                            })
                            .catch((err) => {
                                alert(err)
                                setLoading(false)
                            })
                    }
                }}>Confirmer
                </button>
            </Modal.Footer>
            <Loader show={loading} message={'Envoi de la demande. Veuillez patienter.'}/>
        </Modal>

    )
}


function addDays(current, days) {
    let date = current;
    date.setDate(date.getDate() + days);
    return date;
}