// External Components
import React from "react";
import moment from 'moment'
import 'moment/locale/fr'
import {Calendar, momentLocalizer} from 'react-big-calendar'

// Internal components
import Loader from "../Layout/Components/Loader";
import Event from "../Layout/Components/Modal/Event";
import Booking from "../Layout/Components/Modal/Booking";

// Context
import UserContext from "../userContext";

// Css
import 'react-calendar/dist/Calendar.css';
import '../react-big-calendar.css';

// Utils
import {classNameBlocking, titleName} from "../utils/namings-classnames";

export default function Dashboard() {
    const userContext = React.useContext(UserContext)
        , [firstSelect, setFirstSelect] = React.useState(true) //Property selected
        , [sE, setSE] = React.useState({}) //Selected Event
        , [loading, setLoading] = React.useState(false)

    return (
        <div className={"container-fluid"}>
            <Loader show={loading} message={'Chargement'}/>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <div className={"align-items-center text-sm-left"}>
                    <h1 className={"h3 mb-0 text-gray-800 text-sm-left"}>Planning</h1>
                </div>

                <button className={" d-sm-inline-block btn btn-sm btn-primary shadow-sm align-items-center"}
                        onClick={() => {
                            setLoading(true)
                            const response = userContext.apiReducer('fetchData')
                            response
                                .then(() => {
                                    setLoading(false)
                                })
                                .catch((err) => {
                                    setLoading(false)
                                })
                        }}>
                    {"Recharger"}
                    <i className="fas fa-redo-alt fa-sm text-white-50"/>
                </button>

            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <ul className="nav nav-tabs nav-fill">
                        {
                            userContext.data.houses ?
                                userContext.data.houses.map((house, index) => {
                                    const first = index === 0 && firstSelect ? "nav-item active show" : "nav-item"
                                    return (
                                        <li className={first} key={"nav-item-" + house._id}>
                                            <a className="nav-link" data-toggle="tab" aria-current="page"
                                               href={'#' + house._id} onClick={() => {
                                                if (firstSelect) setFirstSelect(false)
                                            }}>{house.name}</a>
                                        </li>
                                    )
                                })
                                :
                                null
                        }
                    </ul>
                    <div className="tab-content">
                        {
                            userContext.data.houses ?
                                userContext.data.houses.map((house, index) => {
                                    const first = index === 0 && firstSelect ? "tab-pane bg-white border fade in active show" : "tab-pane border bg-white fade in"
                                        , localizer = momentLocalizer(moment)
                                        , eventsList = []

                                    house.interventions.map(intervention => {
                                        const end = intervention.date.duration ?
                                            moment(intervention.date.dateStart).add("hours", intervention.date.duration.split(':')[0]) :
                                            moment(intervention.date.dateEnd)

                                        if (intervention.cancelled) return null


                                        eventsList.push({
                                            start: moment(intervention.date.dateStart),
                                            end: end,
                                            title: "Intervention",
                                            type: "intervention",
                                            class: "interlol",
                                            data: intervention
                                        })
                                        return null
                                    })
                                    house.bookings.map(booking => {
                                        if (booking.cancelled) return null
                                        eventsList.push({
                                            start: moment(booking.dates.start),
                                            end: moment(booking.dates.end),
                                            title: titleName(booking.platform),
                                            type: "booking",
                                            data: booking
                                        })
                                        return null
                                    })

                                    eventsList.sort((a, b) => a.type - b.type)

                                    return (
                                        <div key={"tab-pane-" + house._id}>
                                            <p>{house.address.city} {house.address.zip} - {house.address.number} {house.address.road} {house.address.other}</p>
                                            <Calendar localizer={localizer} events={eventsList}
                                                      startAccessor="start" endAccessor="end"
                                                      views={['month']} style={{height: 500}}
                                                      eventPropGetter={(e) => {
                                                          if (e.type === "intervention") {
                                                              return {className: "intervention-calendar"}
                                                          } else {
                                                              return {className: classNameBlocking(e.title)}
                                                          }
                                                      }}
                                                      components={{
                                                          toolbar: (toolbar) => customTB(toolbar, setSE)
                                                      }}
                                                      onSelectEvent={(event) => {
                                                          setSE(event.data)
                                                      }}/>
                                            {showModal(sE, setSE, house, () => {
                                                setSE({})
                                                setLoading(true)
                                                const response = userContext.apiReducer('fetchData')
                                                response
                                                    .then(() => {
                                                        setLoading(false)
                                                    })
                                                    .catch((err) => {
                                                        setLoading(false)
                                                    })
                                            })}
                                        </div>
                                    )
                                })
                                :
                                null
                        }
                    </div>
                    {
                        userContext.data.houses ?
                            userContext.data.houses.length === 0
                                ?
                                <div className={"col-md-12 mb-4"}>
                                    <div className="card">
                                        <div className="card-header">
                                            Votre propriété n'est pas encore enregistrée dans nos systèmes, ne vous en
                                            faites pas elle devrait y être d'ici peu.
                                        </div>
                                    </div>
                                </div>
                                : null
                            : null
                    }
                </div>
            </div>
        </div>
    )
}

function showModal(selected, setSelected, house, closeAndReload) {
    console.log(selected)
    if (selected.platform) {
        console.log(selected)
        return <Event data={selected} close={() => {
            setSelected({})
        }}/>
    }
    if (selected.addBooking) {
        return <Booking data={selected} house={house} close={() => {
            closeAndReload()
        }}/>
    }
}

function customTB(toolbar, setSelected) {
    const goToBack = () => {
        let mDate = toolbar.date;
        let newDate = new Date(
            mDate.getFullYear(),
            mDate.getMonth() - 1,
            1);
        toolbar.onNavigate('prev', newDate);
    };

    const goToNext = function () {
        let mDate = toolbar.date;
        let newDate = new Date(
            mDate.getFullYear(),
            mDate.getMonth() + 1,
            1);
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
        toolbar.onNavigate('next', newDate);
    };

    const goToCurrent = () => {
        setSelected({addBooking: true})
    };

    const label = () => {
        const date = moment(toolbar.date);
        return (
            <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
        );
    };

    return (
        <div className={'rbc-toolbar'}>
            <span className={'rbc-toolbar-label'}>{label()}</span>

            <div className={'rbc-btn-group'}>
                <button onClick={goToBack}>Précédent</button>
                <button onClick={goToCurrent}>Réserver</button>
                <button onClick={goToNext}>Suivant</button>
            </div>
        </div>
    );
}