import React from "react";
import UserContext from '../userContext'
import {Redirect, Route} from "react-router-dom";
import Layout from "../Layout/Layout";

export default function LoginRoute({component: Component, ...rest}) {
    const userContext = React.useContext(UserContext)

    if (userContext.isLogged()) {
        return (
            <Route {...rest} render={() => {
                return (
                        <Layout>
                            <Component/>
                        </Layout>
                    )
            }}/>
        )
    } else {
        return (
            <Redirect to={'/login'}/>
        )
    }
}