import React from "react";

export default function Error () {
    return (
        <div className="container-fluid">

            <div className="text-center">
                <div className="error mx-auto" data-text="404">404</div>
                <p className="lead text-gray-800 mb-5">Page introuvable</p>
                <p className="text-gray-500 mb-0">Oups ce que vous cherchez est dans un autre chateau...</p>
                <a href="/">&larr; Retourner au Dashboard</a>
            </div>

        </div>
    )
}