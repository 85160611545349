export function classNameBlocking(value) {
    switch (value) {
        case "Blocage client":
            return "block-client"

        case "MYPacome":
            return "block-pm"

        case "Airbnb":
            return "block-airbnb"

        case "HomeAway":
            return "block-ha"

        case "Booking":
            return "block-booking"

        default:
            return ""
    }
}

export function titleName (value) {
    if (value.startsWith("-//Airbnb Inc")) {
        return "Airbnb"
    } else if (value.startsWith("-//HomeAway.com")) {
        return "HomeAway"
    } else if (value.startsWith("-//admin.booking.com")) {
        return "Booking"
    } else if (value.startsWith("Planning Manager")) {
        return "MYPacome"
    }else if (value.startsWith("ical-gites")) {
        return "Gites de France"
    } else {
        return value
    }
}