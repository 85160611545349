import React from 'react'


export default function Loader(props) {
    if (props.show) {
        return (
            <div>
                <div className="overlay show"></div>
                <div className="spanner show">
                    <div className="loader"></div>
                    <p>{props.message}</p>
                </div>
            </div>
        )
    }

    return (
        <div> </div>
    )
}