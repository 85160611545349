import React from 'react'
import UserContext from "../userContext";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import Loader from "../Layout/Components/Loader";


export default function SetNewPassword() {
    const [password, setPassword] = React.useState('')
        , [verify, setVerify] = React.useState('')
        , [loading, setLoading] = React.useState(false)

    const userContext = React.useContext(UserContext)
        , history = useHistory()
        , params = useParams()



    return (
        <div className="bg-gradient-primary">
            <div className="container min-vh-100">
                <div className="row min-vh-100 align-content-center">

                    <div className="col-xl-10 col-lg-12 col-md-9">

                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-block bg-login-image"/>
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">MyPacome</h1>
                                                <h2 className="h6 text-gray-900 mb-4">Créez vos accès à l'interface clients</h2>
                                            </div>
                                            <form className="user" onSubmit={(e) => {
                                                e.preventDefault()
                                                if (password !== '' && password === verify) {
                                                    setLoading(true)
                                                    const response = userContext.apiReducer('creation', {
                                                        password: password,
                                                        token: params.token
                                                    })
                                                    response
                                                        .then(() => {
                                                            alert(
                                                                'Vos accès ont bien été créés. \n' +
                                                                'Vous allez être redirigé vers la page de connexion.'
                                                            )
                                                            setLoading(false)
                                                            history.push('/')
                                                        })
                                                        .catch((err) => {
                                                            if (err.message === 'Request failed with status code 405') {
                                                                history.push('/')
                                                            } else {
                                                                alert('Erreur')
                                                            }
                                                            setLoading(false)
                                                        })
                                                } else {
                                                    alert("Veuillez saisir le même mot de passe pour les deux champs.")
                                                }
                                            }}>
                                                <div className="form-group">
                                                    <input type="password" className="form-control form-control-user"
                                                           id="passwordInput" aria-describedby="passwordHelp"
                                                           placeholder="Nouveau mot de passe" value={password}
                                                           onChange={e => setPassword(e.target.value)} required/>
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" className="form-control form-control-user"
                                                           id="verifyInput" aria-describedby="passwordHelp"
                                                           placeholder="Confirmer votre nouveau mot de passe"
                                                           value={verify}
                                                           onChange={e => setVerify(e.target.value)} required/>
                                                </div>
                                                <button className={"btn btn-primary btn-user btn-block"}
                                                        type={"submit"}>
                                                    Créer mes accès
                                                </button>
                                            </form>
                                            <hr/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Loader show={loading} message={'Chargement'}/>
        </div>
    )
}