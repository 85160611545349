import React from 'react'
import UserContext from "../userContext";
import {useHistory} from "react-router-dom";

//import components
import Loader from "../Layout/Components/Loader";


export default function ResetPassword() {
    const [email, setEmail] = React.useState('')
        , [loading, setLoading] = React.useState(false)
        , userContext = React.useContext(UserContext)
        , history = useHistory();


    return (
        <div className="bg-gradient-primary">
            <div className="container min-vh-100">
                <div className="row min-vh-100 align-content-center">

                    <div className="col-xl-10 col-lg-12 col-md-9">

                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-block bg-login-image"/>
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">MyPacome</h1>
                                                <h2 className="h6 text-gray-900 mb-4">Mot de passe oublié</h2>
                                            </div>
                                            <form className="user" onSubmit={(e) => {
                                                e.preventDefault()
                                                setLoading(true)
                                                userContext.apiReducer('reset', {
                                                    email: email,
                                                })
                                                    .then(() => {
                                                        setLoading(false)
                                                        alert(
                                                            'Email de réinitialisation envoyé, veuillez regarder votre boîte mail. \n' +
                                                            'Vous allez être redirigé vers la page de connexion.'
                                                        )
                                                        history.push('/')
                                                        setLoading(false)
                                                    })
                                                    .catch((err) => {
                                                        setLoading(false)
                                                        console.log(err)
                                                        switch (err.message) {
                                                            case 'Request failed with status code 500':
                                                                return alert('Erreur du serveur veuillez contacter informatique@mypacome.fr')
                                                            case 'Request failed with status code 404':
                                                                return alert('Compte inconnu.')
                                                            default:
                                                                return alert('Une erreur est survenue')
                                                        }
                                                    })
                                            }}>
                                                <div className="form-group">
                                                    <input type="email" className="form-control form-control-user"
                                                           id="exampleInputEmail" aria-describedby="emailHelp"
                                                           placeholder="Adresse email" value={email}
                                                           onChange={(e) => {
                                                               setEmail(e.target.value)
                                                           }} required/>
                                                </div>
                                                <button className={"btn btn-primary btn-user btn-block"}
                                                        type={"submit"}>
                                                    Reinitialiser le mot de passe
                                                </button>
                                            </form>
                                            <hr/>
                                            <div className="text-center">
                                                <button className="btn btn-user btn-block" onClick={() => {
                                                    history.push('/')
                                                }}>
                                                    Retour
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Loader show={loading} message={'Envoi de la demande. Veuillez patienter.'}/>
        </div>
    )
}