// External components
import React from "react";
import {Modal} from 'react-bootstrap';

// Utils
import {titleName} from "../../../utils/namings-classnames";

export default function Event(props) {
    console.log(props.dates)
    return (
        <Modal show={props.data.dates.start} onHide={() => props.close()}>
            <Modal.Header closeButton>
                <Modal.Title>{titleName(props.data.platform)}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Cet événement dure du {toLocalDate(props.data.dates.start)} au {toLocalDate(props.data.dates.end)}</p>
                {!(['Blocage client', 'Planning Manager (Blocking Zone)'].includes(props.data.platform)) ?
                    <div>
                        <ConditionalP value={props.data.adults} name={"Adultes"} />
                        <ConditionalP value={props.data.kids} name={"Enfants"} />
                    </div>
                    :
                    null
                }
            </Modal.Body>

        </Modal>

    )
}

function ConditionalP(props) {
    if (props.value) {
        return <p>{props.name + " : " + props.value}</p>
    }
    return null
}

function toLocalDate(date) {
    const local = new Date(date)
    return local.toLocaleDateString()
}