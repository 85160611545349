import React from "react";

export default function Loading() {
    return (
        <div>
            <div className="animated fadeIn pt-1 text-center">Chargement...</div>
            <div className="spinner-border bg-gradient-primary" role="status">
                <span className="sr-only bg-gradient-primary"/>
            </div>
        </div>

    )
}